import React, { useContext, useEffect } from "react";
import SKUComponent from "../components/SKU";
import { Context } from "../components/Context";
import { navigate } from "@reach/router";
import { isLoggedIn } from "../utils/auth";

const SKUTemplate = ({ pageContext }) => {
  const { SKU } = pageContext;
  const { skuData } = useContext(Context);

  const filteredData = skuData.find(({ data }) => data.SKU === SKU);
  const data = filteredData === undefined ? null : filteredData.data;
  useEffect(() => {
    !isLoggedIn() && navigate(`/login`);
  }, []);
  if (!data) {
    return null;
  } else return <SKUComponent data={data} SKU={SKU} />;
};
export default SKUTemplate;
