import Modal from "@material-ui/core/Modal";
import React from "react";

export const SizeGuide = ({ open, setOpen }) => {
  return (
    <Modal
      onClose={() => setOpen(false)}
      open={open}
      aria-labelledby="size-guide"
      aria-describedby="size-guide"
    >
      <p>size guide</p>
    </Modal>
  );
};
export default SizeGuide;
