import React, { useContext, useState, useEffect, useCallback } from "react";

import { createCheckout } from "../../graphql/mutations";
import { createProduct, updateProduct } from "src/graphql/myMutations";

import { API, graphqlOperation } from "aws-amplify";
import { Context } from "../Context";
import styles from "./styles";
import { Box, Grid, Typography, Button, IconButton } from "@material-ui/core";

import isEmpty from "lodash/isEmpty";
import Select from "../Select";
import { useFormik } from "formik";

import { Products, Sizes, Gallery } from "./elements";
import { ProductSchema } from "src/utils/validation";
import { nanoid } from "nanoid";

import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import { countPrice } from "src/utils/helpers";
import { useSnackbar } from "notistack";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { navigate } from "gatsby";
import SizeGuide from "./elements/SizeGuide";
import Analytics from "@aws-amplify/analytics";

const SKU = ({ SKU, data }) => {
  const { checkout, fetchCheckouts, products, fetchProducts } = useContext(
    Context
  );

  const [product, setProduct] = useState({ SKU: SKU, items: [] });
  const [open, setOpen] = useState(false);
  const [newCheckoutID] = useState(nanoid);

  const { enqueueSnackbar } = useSnackbar();

  const classes = styles();
  const Wholesale_Price = data.Wholesale_Price;
  const RRP = data.RRP;
  const name = data.Name;
  const brand = data.BrandName[0];

  const items = product.items;
  const Colors = data.Colors;
  const Available_sizes = data.Available_sizes;
  const SKU_Photos = data.SKU_Photos;
  const composition = data.Composition;

  const sizeGuide = data.Size_guide;

  const title = data.Title;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      color: Colors.length > 1 ? "" : Colors[0],
      items: [],
      SKU: SKU,
      price: 0,
      WHP: parseInt(Wholesale_Price),
      name: name,
      brand: brand,
      selection: Boolean,
      composition: composition,
      RRP: parseInt(RRP),
    },
    validationSchema: ProductSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const price = countPrice(values.items, Wholesale_Price);
      console.log(values);
      values.price = price;
      const jsonItems = JSON.stringify(values.items);

      values.items = jsonItems;
      const existingVariant = product.items.find(
        (variant) => variant.color === values.color
      );

      if (isEmpty(checkout)) {
        try {
          await API.graphql(
            graphqlOperation(createCheckout, { input: { id: newCheckoutID } })
          );
          await fetchCheckouts();
        } catch (err) {
          console.log(err);
        }
      }
      if (existingVariant) {
        try {
          const index = product.items.findIndex(
            (variant) => variant.color === values.color
          );

          let productCopy = product;

          productCopy.items[index] = values;
          productCopy.items[index].id = existingVariant.id;
          productCopy.items[index].checkoutId = checkout.id;
          productCopy.items[index].price = price;

          await API.graphql(
            graphqlOperation(updateProduct, {
              input: productCopy.items[index],
            })
          );

          enqueueSnackbar("Items have been updated");
          setProduct(productCopy);
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          values.id = null;
          values.checkoutId = isEmpty(checkout) ? newCheckoutID : checkout.id;

          await API.graphql(graphqlOperation(createProduct, { input: values }));
          setProduct({ ...product, items: [...product.items, values] });
          enqueueSnackbar("Items have been added");
          Analytics.record("add-to-cart");
        } catch (err) {
          console.log(err);
        }
      }

      mapSizesToState();
      fetchProducts(checkout.id || newCheckoutID);

      setSubmitting(false);
    },
  });

  const mapSizesToState = useCallback(() => {
    const updatedItems = [];
    Available_sizes.forEach((size, index) => {
      updatedItems.push({ [size]: "" });
    });

    formik.setValues({ ...formik.values, items: updatedItems });
  }, [Available_sizes, formik]);

  const filterProducts = useCallback(async () => {
    const productItems = products.filter((item) => item.SKU === SKU);

    setProduct({ ...product, items: productItems });
  }, [SKU, product, products]);

  useEffect(() => {
    filterProducts();
    mapSizesToState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  useEffect(() => {
    fetchCheckouts();
  }, [fetchCheckouts]);

  if (!data) {
    return null;
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box mt={4} mb={10}>
          <IconButton
            onClick={() => navigate("/home")}
            color="primary"
            size="medium"
          >
            <ArrowBackIcon />
          </IconButton>
          <Grid container spacing={10}>
            <Grid item xs={12} md={6}>
              <Gallery SKU_Photos={SKU_Photos} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={4}>
                <h1 className={classes.title}>{title}</h1>
              </Box>

              <Box mb={3}>
                <Typography variant="body1">SKU: {SKU}</Typography>

                <Typography variant="body1">
                  {composition && composition.toUpperCase()}
                </Typography>
              </Box>

              <Box mb={4}>
                <Typography variant="body2">
                  WHP: {Wholesale_Price} EURO RRP: {RRP} EURO
                </Typography>
              </Box>
              <Box className={classes.sizeWrapper}>
                <Box mr={4} display="inline-flex" width="100%">
                  <Select
                    onChange={formik.handleChange}
                    value={formik.values.color}
                    name="color"
                    options={Colors}
                    helperText={formik.errors.color}
                    className={classes.select}
                  />
                </Box>

                {sizeGuide && (
                  <Typography
                    onClick={() => setOpen(true)}
                    className={classes.sizeGuide}
                    variant="caption"
                  >
                    Size guide
                  </Typography>
                )}
              </Box>

              <Sizes
                formik={formik}
                Available_sizes={Available_sizes}
                Wholesale_Price={Wholesale_Price}
              />
              <Box mt={4}>
                {" "}
                <Products
                  items={items}
                  product={product}
                  setProduct={setProduct}
                />
              </Box>

              <Button
                disabled={formik.isSubmitting}
                type="submit"
                className={classes.button}
              >
                Add to Order
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
      <SizeGuide open={open} setOpen={setOpen} />
    </>
  );
};
export default SKU;
