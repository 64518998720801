import React, { useMemo } from "react";
import styles from "../../styles";
import ImageGallery from "react-image-gallery";
import Img from "gatsby-image";
export const Gallery = ({ SKU_Photos }) => {
  const classes = styles();

  const renderItem = (item) => {
    const itemSrc = item.fluid;

    return <Img fluid={itemSrc} />;
  };

  const photos = useMemo(
    () =>
      SKU_Photos.localFiles.map(({ childImageSharp, url }) => ({
        thumbnail: "",
        thumbnailClass: classes.image,
        fluid: childImageSharp.fluid,
        original: "",
      })),
    [classes.image, SKU_Photos]
  );

  return (
    <ImageGallery
      items={photos}
      thumbnailPosition="right"
      showFullscreenButton={false}
      useBrowserFullscreen={false}
      showPlayButton={false}
      showNav={false}
      renderItem={renderItem}
      renderThumbInner={renderItem}
    />
  );
};

export default Gallery;
