import { makeStyles } from "@material-ui/core";
const styles = makeStyles((theme) => ({
  image: {
    margin: 0,
    border: "none",
    marginTop: 0,
    cursor: "pointer",
  },

  sizeGuide: {
    textDecoration: "underline",

    [theme.breakpoints.down("xs")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  input: {
    width: "6rem",
    marginRight: "1.375rem",
  },
  button: {
    fontSize: "1.5rem",
    marginTop: "2.875rem",
    textTransform: "none",
  },
  select: {
    [theme.breakpoints.down("xs")]: {
      width: "164px",
    },
  },
  sizeWrapper: {
    display: "inline",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },
  title: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "36px",
    marginTop: "4px",
    lineHeight: "18px",
  },
}));

export default styles;
