import React, { useMemo } from "react";
import { Box, InputBase, Typography } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import styles from "../../styles";

export const Sizes = ({ Available_sizes, formik, Wholesale_Price }) => {
  const classes = styles();
  const mappedSizes = useMemo(
    () =>
      Available_sizes.map((size, index) => {
        if (!isEmpty(formik.values.items)) {
          const quantity = formik.values.items[index][size];
          const price = quantity * Wholesale_Price;
          return (
            <Box mt={2} key={size}>
              <InputBase
                value={quantity || ""}
                className={classes.input}
                onChange={formik.handleChange}
                name={`items[${index}][${size}]`}
                type="number"
                placeholder="0"
              />
              <Typography component="span">
                {size} {price === 0 || isNaN(price) ? "" : `: €${price}`}
              </Typography>
            </Box>
          );
        } else return null;
      }),
    [
      Wholesale_Price,
      classes.input,
      Available_sizes,
      formik.handleChange,
      formik.values.items,
    ]
  );
  return mappedSizes;
};

export default Sizes;
