import React, { useMemo } from "react";
import DeleteButton from "./DeleteButton";
import { Box, Typography } from "@material-ui/core";

export const Products = ({ items, product, setProduct }) =>
  useMemo(
    () =>
      items.map((productVariant) => {
        const color = productVariant.color;
        const items = productVariant.items;
        const parsedItems = JSON.parse(items);
        const price = productVariant.price;

        return (
          <Box key={color}>
            <Typography component="span" variant="body2">
              {color}:
            </Typography>

            {items &&
              parsedItems.map((item) =>
                Object.entries(item).map((entry) => (
                  <Box display="inline" key={entry[0]}>
                    &nbsp;
                    <Typography component="span" variant="body2">
                      {entry[0]} = {entry[1] === "" ? 0 : entry[1]},
                    </Typography>
                  </Box>
                ))
              )}

            <Typography component="span" variant="body2">
              &nbsp;Total: €{price}
            </Typography>
            <DeleteButton
              color={color}
              product={product}
              setProduct={setProduct}
            />
          </Box>
        );
      }),
    [items, product, setProduct]
  );

export default Products;
