import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { deleteProduct } from "src/graphql/myMutations";
import styles from "./styles";
import { API, graphqlOperation } from "aws-amplify";
import { useSnackbar } from "notistack";
import Analytics from "@aws-amplify/analytics";

export const DeleteButton = ({ setProduct, product, color }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = styles();
  const handleDelete = (color) => {
    try {
      const clickedVariant = product.items.find(
        (variant) => variant.color === color
      );

      API.graphql(
        graphqlOperation(deleteProduct, {
          input: { id: clickedVariant.id },
        })
      );
      enqueueSnackbar("Items have been deleted");
      Analytics.record("remove-from-cart");
    } catch (err) {
      console.log(err);
    }

    const filteredItems = product.items.filter(
      (product) => product.color !== color
    );
    setProduct({ ...product, items: filteredItems });
  };

  return (
    <DeleteIcon className={classes.icon} onClick={() => handleDelete(color)} />
  );
};
export default DeleteButton;
